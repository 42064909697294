import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { loginApi } from './services/login';
import userSlice from './slices/userSlice';
import activeWidgetReducer from './slices/activeWidgetSlice';

export const store = configureStore({
    reducer: {
        [loginApi.reducerPath]: loginApi.reducer,
        userSlice,
        activeWidget: activeWidgetReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(loginApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
