import { useConfiguration } from '../../../hooks/useConfiguration';
import { useDispatch } from 'react-redux';
import { PageSlots } from './PageSlots';
import {
    setActivePageId,
    setActiveSlotId,
    setActiveWidgetId,
} from '../../../app/slices/activeWidgetSlice';
import { Configuration } from '@newco-widgets/types';

function getResetValues(pageId: string, configuration?: Configuration) {
    const activePage = configuration?.find((page) => page.id === pageId);
    const activeSlot = activePage?.slots.at(0);
    const activeWidget = activeSlot?.widgets.at(0);
    return {
        activeSlotId: activeSlot?.id,
        activeWidgetId: activeWidget?.id,
    };
}
export function WidgetList() {
    const { data: configuration } = useConfiguration();
    const dispatch = useDispatch();
    return (
        <div className="space-y-5">
            <h2 className="text-center text-xl font-bold">Widget List</h2>
            <div className="flex items-center space-x-3">
                <label className="font-semibold">Active Page: </label>
                <select
                    className="flex-1 rounded-lg border px-2.5 py-2 text-sm"
                    onChange={(event) => {
                        const pageId = event.target.value;
                        const values = getResetValues(pageId, configuration);
                        dispatch(setActivePageId(pageId));
                        if (values.activeSlotId) {
                            dispatch(setActiveSlotId(values.activeSlotId));
                        }
                        if (values.activeWidgetId) {
                            dispatch(setActiveWidgetId(values.activeWidgetId));
                        }
                    }}
                >
                    {configuration?.map((page) => (
                        <option key={page.id} value={page.id}>
                            {page.metaData.title}
                        </option>
                    ))}
                </select>
            </div>
            <PageSlots />
        </div>
    );
}
