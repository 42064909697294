import { Routes, Route, Navigate } from 'react-router-dom';
import 'ui/styles.css';
import Home from './components/Home/Home';
import { Editor } from './components/Editor';

function App() {
    return (
        <div>
            <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<Editor />} path="/editor" />
                <Route element={<Navigate replace to="/" />} path="*" />
            </Routes>
        </div>
    );
}

export default App;
