import { Configuration } from '@newco-widgets/types';
import { useQueryClient } from '@tanstack/react-query';
import {
    useActiveConfigValues,
    useConfiguration,
} from '../../../hooks/useConfiguration';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { PageSlotItem } from './PageSlotItem';

function useDragMutation() {
    const config = useConfiguration().data;
    const queryClient = useQueryClient();
    const { activePageId } = useActiveConfigValues();

    return function onDragEnd(result: DropResult) {
        if (!result.destination) return;
        const configuration = structuredClone(config) as Configuration;
        const activePageSlots =
            configuration?.find(({ id }) => id === activePageId)?.slots ?? [];
        const { source, destination } = result;
        const sourceWidgets =
            activePageSlots.at(parseInt(source.droppableId))?.widgets ?? [];
        // Remove the widget from the source slot
        const [removed] = sourceWidgets.splice(source.index, 1);
        const destinationWidgets =
            activePageSlots.at(parseInt(destination.droppableId))?.widgets ??
            [];
        // Add the widget to the destination slot at the correct index
        if (source.droppableId !== destination.droppableId) {
            destinationWidgets.splice(destination.index, 0, removed);
        } else sourceWidgets.splice(destination.index, 0, removed);
        queryClient.setQueryData(['configuration'], () => configuration);
    };
}

export function PageSlots() {
    const config = useConfiguration().data;
    const { activePageId } = useActiveConfigValues();
    const onDragEnd = useDragMutation();
    const activePageSlots =
        config?.find((page) => page.id === activePageId)?.slots ?? [];

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {activePageSlots.map((slot, slotIndex) => (
                <PageSlotItem key={slot.id} slot={slot} slotIndex={slotIndex} />
            ))}
        </DragDropContext>
    );
}
