import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Button } from 'ui';
import { Modal } from 'ui';
import useModal from '../../hooks/useModal';

const Home = () => {
    const navigate = useNavigate();
    const navigateUI = () => {
        navigate('/ui');
    };
    const navigateLogin = () => {
        navigate('/login');
        console.log('ndjsd');
    };
    // const handleCLick = () => {
    //   console.log('ndjsd')
    // }

    async function handleCLick() {
        try {
            const response = await axios.get(
                '/newco/internal/v1/page/templates'
            );
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    const { isOpen, toggle } = useModal();

    return (
        <div className="m-4">
            <Button onClick={toggle}>Add New Page</Button>
            <Modal isOpen={isOpen} toggle={toggle}>
                <div>Modal box</div>
            </Modal>
        </div>
    );
};

export default Home;
