import { useConfiguration } from '../../hooks/useConfiguration';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { WidgetList } from './components/WidgetList';
import { WidgetSettingsPanel } from './components/WidgetSettingsPanel';

export function Editor() {
    const { data: configuration } = useConfiguration();
    const { pageId } = useSelector((state: RootState) => state.activeWidget);
    const pageConfiguration = configuration?.find(({ id }) => id === pageId);
    const slotsConfiguration = pageConfiguration?.slots;

    return (
        <div className="grid grid-cols-[300px_1fr_400px] p-10">
            <WidgetList />
            <div className="space-y-5">
                <h2 className="text-center text-xl font-bold">Configuration</h2>
                <p className="mx-auto max-w-md text-center">
                    The configuration would be passed down to the IFrame, which
                    would render the widgets based on it
                </p>
                <div className="flex justify-center">
                    <pre>{JSON.stringify(slotsConfiguration, null, 2)}</pre>
                </div>
            </div>
            <WidgetSettingsPanel />
        </div>
    );
}
