import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ActiveWidgetState {
    /**
     * The id of the active widget
     */
    widgetId: string | null;
    /**
     * The id of the active page
     */
    pageId: string | null;
    /**
     * The id of the active slot
     */
    slotId: string | null;
}

const initialState: ActiveWidgetState = {
    widgetId: null,
    pageId: null,
    slotId: null,
};

export const activeWidgetSlice = createSlice({
    name: 'activeWidget',
    initialState,
    reducers: {
        setActivePageId: (state, action: PayloadAction<string>) => {
            state.pageId = action.payload;
        },
        setActiveWidgetId: (state, action: PayloadAction<string>) => {
            state.widgetId = action.payload;
        },
        setActiveSlotId: (state, action: PayloadAction<string>) => {
            state.slotId = action.payload;
        },
    },
});

export const { setActivePageId, setActiveWidgetId, setActiveSlotId } =
    activeWidgetSlice.actions;

export default activeWidgetSlice.reducer;
