import { useActiveConfigValues } from '../../../../hooks/useConfiguration';
import { useState } from 'react';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';

export function TogglePicker({ propName }: BasePickerProps) {
    const { activeWidget } = useActiveConfigValues();
    const [isChecked, setChecked] = useState(
        activeWidget?.props[propName] as boolean
    );
    const updateProperty = useUpdatePropInConfiguration(propName);
    return (
        <label className="relative inline-flex cursor-pointer items-center">
            <input
                checked={isChecked}
                className="peer sr-only"
                type="checkbox"
                value=""
                onChange={() => {
                    setChecked(!isChecked);
                    updateProperty(!isChecked);
                }}
            />
            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800" />
        </label>
    );
}
