import { useQuery } from '@tanstack/react-query';
import configuration from '../utils/configuration.json';
import { Configuration } from '@newco-widgets/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import {
    setActivePageId,
    setActiveSlotId,
    setActiveWidgetId,
} from '../app/slices/activeWidgetSlice';

export function useConfiguration() {
    const dispatch = useDispatch();
    return useQuery<Configuration>(
        ['configuration'],
        () => {
            return Promise.resolve(configuration as Configuration);
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            onSettled(configuration) {
                const activePage = configuration?.at(0);
                const activeSlot = activePage?.slots.at(0);
                const activeWidget = activeSlot?.widgets.at(0);
                if (activePage) dispatch(setActivePageId(activePage.id));
                if (activeSlot) dispatch(setActiveSlotId(activeSlot.id));
                if (activeWidget) dispatch(setActiveWidgetId(activeWidget.id));
            },
        }
    );
}

/**
 * A utility hook to quickly access the active configuration values like the active page, slot, and widget.
 */
export function useActiveConfigValues() {
    const configuration = useConfiguration().data;
    const { pageId, slotId, widgetId } = useSelector(
        (state: RootState) => state.activeWidget
    );

    const activePage = configuration?.find((page) => page.id === pageId);
    const activeSlot = activePage?.slots.find((slot) => slot.id === slotId);
    const activeWidget = activeSlot?.widgets.find(({ id }) => id === widgetId);

    return {
        activePage,
        activeSlot,
        activeWidget,
        activePageId: pageId,
        activeSlotId: slotId,
        activeWidgetId: widgetId,
    };
}
