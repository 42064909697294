import { Widget } from '@newco-widgets/types';
import { useDispatch } from 'react-redux';
import { useActiveConfigValues } from '../../../hooks/useConfiguration';
import { Draggable } from 'react-beautiful-dnd';
import {
    setActiveSlotId,
    setActiveWidgetId,
} from '../../../app/slices/activeWidgetSlice';

interface SlotWidgetItemProps {
    widget: Widget;
    widgetIndex: number;
    slotId: string;
}

export function SlotWidgetItem({
    widget,
    widgetIndex,
    slotId,
}: SlotWidgetItemProps) {
    const dispatch = useDispatch();
    const { activeWidgetId } = useActiveConfigValues();
    const isActiveListItem = activeWidgetId === widget.id;

    return (
        <Draggable key={widget.id} draggableId={widget.id} index={widgetIndex}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    className={`mb-5 w-full rounded-lg bg-blue-600 p-2 text-white ${
                        isActiveListItem ? 'bg-green-600' : ''
                    }`}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        dispatch(setActiveWidgetId(widget.id));
                        dispatch(setActiveSlotId(slotId));
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {widget.type}
                </div>
            )}
        </Draggable>
    );
}
