import { useActiveConfigValues } from '../../../hooks/useConfiguration';
import { Picker, WidgetPickerMapping } from '@newco-widgets/types';
import { FC } from 'react';
import {
    MultiImagePicker,
    MultiLinePicker,
    SingleImagePicker,
    SingleLinePicker,
    TogglePicker,
} from './Pickers';
import { BasePickerProps } from '../../../utils/pickerHelper';

const PickerWidgetMapping: Record<Picker, FC<BasePickerProps>> = {
    [Picker.MultiImagePicker]: MultiImagePicker,
    [Picker.TogglePicker]: TogglePicker,
    [Picker.MultiLinePicker]: MultiLinePicker,
    [Picker.SingleImagePicker]: SingleImagePicker,
    [Picker.SingleLinePicker]: SingleLinePicker,
};

export function WidgetSettingsPanel() {
    const { activeWidget } = useActiveConfigValues();
    const pickerPropsMapping = activeWidget
        ? WidgetPickerMapping[activeWidget.type] ?? {}
        : {};
    const widgetProps = Object.keys(pickerPropsMapping) ?? [];

    return (
        <div>
            <h2 className="mb-10 text-center text-xl font-bold">
                Settings ({activeWidget?.type})
            </h2>
            <div className="space-y-5">
                {widgetProps.map((propName) => {
                    const pickerName = pickerPropsMapping[propName] as Picker;
                    const Settings = PickerWidgetMapping[pickerName];
                    if (!pickerName || !Settings) return null;
                    return (
                        <div key={propName} className="space-y-3">
                            <label
                                className="inline-block text-lg font-bold capitalize"
                                htmlFor={propName}
                            >
                                {propName}
                            </label>
                            <Settings propName={propName} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
