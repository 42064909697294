import { useState } from 'react';
import { useActiveConfigValues } from '../../../../hooks/useConfiguration';
import { Image } from '@newco-widgets/types';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';

export function MultiImagePicker({ propName }: BasePickerProps) {
    const [length, setLength] = useState(1);
    const updateProperty = useUpdatePropInConfiguration(propName);
    const { activeWidget } = useActiveConfigValues();
    const images = activeWidget?.props[propName] as Image[];
    const imageArray = Array.from({ length }).map((value, index) => ({
        id: index,
        src: images?.at(index)?.src ?? '',
        alt: images?.at(index)?.alt ?? '',
    }));

    return (
        <div className="space-y-5">
            {imageArray.map(({ id }) => (
                <div key={id} className="space-y-3">
                    <label
                        className="block w-full rounded-lg border bg-transparent p-2.5 hover:cursor-pointer"
                        htmlFor={propName + id}
                    >
                        Pick Image {id + 1}
                    </label>
                    <input
                        accept="image/*"
                        className="hidden"
                        id={propName + id}
                        type="file"
                        onChange={(event) => {
                            if (event.target.files && event.target.files[0]) {
                                imageArray[id].src = event.target.files[0].name;
                                const images = imageArray.map(
                                    ({ id, ...image }) => image
                                );
                                updateProperty(images);
                            }
                        }}
                    />
                    <label
                        className="inline-block text-sm font-semibold"
                        htmlFor={`image-single-${id + 1}`}
                    >
                        Image {id + 1} ALT Text
                    </label>
                    <input
                        className="block w-full rounded-lg border p-2.5"
                        id={`image-single-${id + 1}`}
                        placeholder={`Enter image ${id + 1} alt text here...`}
                        value={imageArray[id].alt}
                        onChange={(event) => {
                            imageArray[id].alt = event.target.value;
                            const images = imageArray.map(
                                ({ id, ...image }) => image
                            );
                            updateProperty(images);
                        }}
                    />
                </div>
            ))}
            <div className="text-center">
                <button
                    className="rounded bg-teal-600 p-2 text-white"
                    type="button"
                    onClick={() => setLength(length + 1)}
                >
                    New Image Block
                </button>
            </div>
        </div>
    );
}
