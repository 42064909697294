import { useActiveConfigValues } from '../../../../hooks/useConfiguration';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';
export function MultiLinePicker({ propName }: BasePickerProps) {
    const updateProperty = useUpdatePropInConfiguration(propName);
    const defaultValue =
        (useActiveConfigValues().activeWidget?.props[propName] as string) ?? '';

    return (
        <textarea
            className="block w-full rounded-lg border p-2.5"
            defaultValue={defaultValue}
            id={propName}
            placeholder="text"
            onChange={(event) => {
                const newValue = event.target.value;
                updateProperty(newValue);
            }}
        />
    );
}
