import { Slot } from '@newco-widgets/types';
import { Droppable } from 'react-beautiful-dnd';
import { SlotWidgetItem } from './SlotWidgetItem';

interface PageSlotItemProps {
    slot: Slot;
    slotIndex: number;
}
export function PageSlotItem({ slotIndex, slot }: PageSlotItemProps) {
    return (
        <div key={slot.id} className="space-y-5">
            <h3 className="text-sm font-semibold">
                Widgets in Slot {slotIndex + 1}
            </h3>
            <Droppable droppableId={String(slotIndex)}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {slot.widgets?.map((widget, index) => (
                            <SlotWidgetItem
                                key={widget.id}
                                slotId={slot.id}
                                widget={widget}
                                widgetIndex={index}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}
