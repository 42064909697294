var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Button.tsx
import { jsx } from "react/jsx-runtime";
var Button = (props) => {
  const defaultClass = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded";
  const fallbackText = "Button";
  const _a = props, { children = fallbackText, className = defaultClass, onClick } = _a, rest = __objRest(_a, ["children", "className", "onClick"]);
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      console.warn("Default Click");
    }
  };
  return /* @__PURE__ */ jsx(
    "button",
    __spreadProps(__spreadValues({
      onClick: handleClick,
      className
    }, rest), {
      children
    })
  );
};

// src/Modal.tsx
import { Fragment, jsx as jsx2 } from "react/jsx-runtime";
var Modal = (props) => {
  return /* @__PURE__ */ jsx2(Fragment, { children: props.isOpen && /* @__PURE__ */ jsx2("div", { className: "modal-overlay", onClick: props.toggle, children: /* @__PURE__ */ jsx2("div", { onClick: (e) => e.stopPropagation(), className: "modal-box", children: props.children }) }) });
};
export {
  Button,
  Modal
};
