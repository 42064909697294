import { useActiveConfigValues } from '../../../../hooks/useConfiguration';
import { Image } from '@newco-widgets/types';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';
export function SingleImagePicker({ propName }: BasePickerProps) {
    const { activeWidget } = useActiveConfigValues();
    const defaultValue = activeWidget?.props[propName] as Image;
    const image = {
        src: defaultValue?.src ?? '',
        alt: defaultValue?.alt ?? '',
    };

    const updateProperty = useUpdatePropInConfiguration(propName);
    return (
        <div className="space-y-3">
            <label
                className="block w-full rounded-lg border bg-transparent p-2.5 hover:cursor-pointer"
                htmlFor={propName}
            >
                Pick Image
            </label>
            <input
                accept="image/*"
                className="hidden"
                id={propName}
                type="file"
                onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                        image.src = event.target.files[0].name;
                        updateProperty(image);
                    }
                }}
            />
            <label
                className="inline-block text-sm font-semibold"
                htmlFor="image-single"
            >
                Alt Text
            </label>
            <input
                className="block w-full rounded-lg border p-2.5"
                id="image-single"
                placeholder="Enter image's alt text here..."
                value={image.alt}
                onChange={(event) => {
                    image.alt = event.target.value;
                    updateProperty({ ...image });
                }}
            />
        </div>
    );
}
