import { useQueryClient } from '@tanstack/react-query';
import { useActiveConfigValues } from '../hooks/useConfiguration';
import { Configuration } from '@newco-widgets/types';

export interface BasePickerProps {
    propName: string;
}

export function useUpdatePropInConfiguration(propName: string) {
    const queryClient = useQueryClient();
    const { activePageId, activeSlotId, activeWidgetId } =
        useActiveConfigValues();

    return function mutate(newValue: unknown) {
        console.log(newValue);
        queryClient.setQueryData<Configuration>(
            ['configuration'],
            (configuration) => {
                if (!configuration) return;
                const newConfiguration = structuredClone(
                    configuration
                ) as Configuration;
                const widget = newConfiguration
                    .find((page) => page.id === activePageId)
                    ?.slots.find((slot) => slot.id === activeSlotId)
                    ?.widgets.find((widget) => widget.id === activeWidgetId);
                if (widget) {
                    widget.props[propName] = newValue;
                }
                return newConfiguration;
            }
        );
    };
}
