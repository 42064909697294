import { useActiveConfigValues } from '../../../../hooks/useConfiguration';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';

export function SingleLinePicker({ propName }: BasePickerProps) {
    const updateProperty = useUpdatePropInConfiguration(propName);
    const { activeWidget } = useActiveConfigValues();
    return (
        <input
            className="block w-full rounded-lg border p-2.5"
            id={propName}
            placeholder="Enter text here..."
            value={(activeWidget?.props[propName] as string) ?? ''}
            onChange={(event) => {
                const newValue = event.target.value;
                updateProperty(newValue);
            }}
        />
    );
}
